/* ScrollableScreen.css */

.scrollable-screen {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.scroll-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1;
}

.scroll-button.left {
    position: absolute;
    left: 0;
}

.scroll-button.right {
    position: absolute;
    right: 0;
}

.scrollable-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.scroll-item {
    min-width: 100%;
    box-sizing: border-box;
}