.coursesCard {
  padding: 50px 0;
}

.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}

.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #3D48A6;
  padding: 15px;
}

.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}

.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}

.coursesCard .rate {
  margin: 20px 0;
  color: #3D48A6;
}

.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}

.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}

.coursesCard .details span {
  color: #3D48A6;
  font-weight: 500;
  font-size: 14px;
}

.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}

.coursesCard h3 {
  font-weight: 500;
  color: #3D48A6;
}

/*-------------online---------*/
.online {
  text-align: center;
}

.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.5s;
}

.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}

.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.online h1 {
  font-weight: 500;
  font-size: 18px;
  margin: 20px 0;
  line-height: 30px;
}

.online p {
  font-weight: 500;
  font-size: 18px;
  margin: 20px 0;
  line-height: 30px;
  color: #3D48A6;
}

.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #3D48A6;
  border-radius: 5px;
}

.online .box .show {
  opacity: 0;
}

.online .box:hover {
  background-color: #3D48A6;
  border-radius: 5px;
  cursor: pointer;
}

.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.online .box:hover h1 {
  color: #fff;
}

.online .box:hover p {
  color: #fff;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content ul {
  text-align: left;
  list-style-type: none;
  padding: 0;
}

.popup-content button {
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border: none;
  background-color: #f44336;
  color: white;
  border-radius: 5px;
}

.accordion {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  max-height: 490px;
  overflow: auto;
}

.card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.card.open {
  border-color: #3b82f6;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.card-header {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #1e293b;
  border-bottom: 1px solid #e0e0e0;
}

.milestone {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 15px;
}

.milestone-1 {
  background-color: #d8b4fe !important;
  /* Light purple */
}

.milestone-2 {
  background-color: #fef3c7 !important;
  /* Light yellow */
  color: #b45309;
}

.milestone-3 {
  background-color: #fecaca !important;
  /* Light red */
}

.milestone-4 {
  background-color: #bbdbcd !important;
  /* Light red */
}

.iconCourse {
  margin-left: auto;
  font-size: 20px;
  color: #3b82f6;
  transition: transform 0.3s ease;
}

.card.open .iconCourse {
  transform: rotate(180deg);
}

.card-body {
  padding: 20px;
  font-size: 15px;
  color: #374151;
  line-height: 1.6;
}

.content-columns {
  display: flex;
  gap: 20px;
}

.content-columns ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.content-columns li {
  position: relative;
  padding-left: 24px;
  /* Increased padding for better alignment */
  margin-bottom: 8px;
  line-height: 1.5;
  /* Adjust line-height for better vertical spacing */
  display: flex;
  align-items: center;
  /* Align dot and text vertically */
}

.content-columns li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #3b82f6;
  font-size: 16px;
  line-height: 0;
  /* Remove line-height from the dot itself */
  margin-top: 1px;
  /* Adjust this value if the dot is slightly off-center */
}

/* Expand animation */
@keyframes expand {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

/*-------------online---------*/
@media screen and (max-width: 768px) {}