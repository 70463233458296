.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}

.testimonal .img {
  margin-left: 30px;
  position: relative;
}

.testimonal img {
  width: 145px;
  height: 145px;
  border-radius: 50%;
}

.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #3D48A6;
  color: #fff;
}



.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
  margin-left: 30px;
  color: #3D48A6;
}

.testimonal .name span {
  color: #3D48A6;
}

.testimonal p {
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 30px;
  color: grey;
  transition: 0.5s;
}

.testimonal .items:hover {
  background-color: #3D48A6;
  color: #fff;
  cursor: pointer;
}

.testimonal .items:hover span,
.testimonal .items:hover h2,
.testimonal .items:hover p {
  color: #fff;
}

.testimonal .items:hover i {
  background-color: #fff;
  color: #3D48A6;
}